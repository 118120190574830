import { Component, OnInit } from '@angular/core';
import { data, event } from 'jquery';
import { MyserviceService } from 'src/app/Login Services';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  dataArr:any;
  data: any;
  // For localhost legal download files path:
  //imageDirectoryPath:any ='http://127.0.0.1:8080/public/schooladmin/storage/app';

   // For localhost download files path for live server
   imageDirectoryPath:any ='https://alexinstitute.com/alexadmin/storage/app';

  constructor(
      private myservice:MyserviceService
  ) { }

  ngOnInit() {

    this.getDownloadData();
    this.DownloadFile();
  }
  getDownloadData()
  {
    this.myservice.getDownload(data).subscribe(res=>{
      this.dataArr=res;
    })
  }
    DownloadFile(): void
    {
      this.myservice.DownloadFile(data).subscribe(data=>{
        this.data =data;
        this.dataArr=this.DownloadFile;
      })
     
      
    }
  }

