<div class="container-fluid">

    <img src="assets/banners/exam.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Examinations  </h1>
 
   
 

  

   <p> Alex Instute Examination is conducted twice in a year. The Two Sessions are Academic and Calendar Session. Academic Session Exams are usually conducted in June-july and Calendar Session Exams are usually conducted in November & Dec. every year.
    The Notification regarding Examination is issued on Website. The Enrolment Last Date is also given in the Notification.
    Preparations of Question Papers are done by well qualified staff members with the help of syllabus.
    Time Table is created and informed t to all branches/Study Centres.<br><br>
    
    
    The valuation will be done strictly and marks will be awarded only on merit basis.
    Once the result is made ready, it is ensured to be available in the student login panel.
    
    The students who score a minimum of 35 marks will be declared as “PASSED”. The students who score below 35 marks will be declared as “FAILED” and will have to reappear for examination in the next Session.<br><br>
    
    (i) The students who score marks from 35-49 Marks will be awarded Third Class<br>
    (ii) The students who score marks from 50-59 Marks will be awarded Second Class<br>
    
    (iii) The students who score > 60 Marks will be awarded First Class. <br>

    (iv) For Others Board/University Examination are held as per the guidance of Board or university as their norms on their campus.
  
  </p>

    

    


