
<h1> Distance Universities Details </h1>
<b>Distance universities in India </b> 

डिस्टेंस एजुकेशन (Distances Education) को हिंदी में “दूरस्थ शिक्षा” भी कहते है, जिसके अंतर्गत पत्राचार तथा ऑनलाइन शिक्षा प्रणाली का प्रयोग किया जाता है, तथा इस शिक्षा पद्धति के जनक पीटर्स ने वर्ष 1973 में इस शिक्षा को ज्ञान, कौशल तथा अभिव्यक्ति प्रदान करने की विधि के रूप में परिभाषित किया है | तथा विश्वविधालय अनुदान आयोग के द्वारा वर्ष (1956-1960) में पत्राचार पाठ्यक्रम शुरू करने का सुझाव प्रस्तुत किया, तथा वर्ष 1996 में दूरस्थ शिक्षा निदेशालय की स्थापना की गयी थी | इस पाठ्यक्रम का मुख्य उद्देश्य योग्य तथा इच्छुक अभ्यर्थियों को शिक्षा सम्बन्धी सुविधाए प्राप्त करना है | जो नियमित रूप से शिक्षा संस्थान जाने में सक्षम नही है | <br>
डिस्टेंस एजुकेशन के अंतर्गत शिक्षक तथा छात्र को एक विशेष स्थान तथा समय पर उपस्थित होने की आवश्यकता नहीं होती है तथा शिक्षक के द्वारा शिक्षा को छात्र तक पहुंचना होता है | इसके साथ ही छात्र न्यूनतम वित्तीय संसाधनों के साथ विभिन्न प्रकार के शिक्षण सम्बन्धी उपकरणों प्राप्त कर सकता है | दूरस्थ शिक्षा के लिए पंजीकृत विश्वविधालयों के द्वारा स्नातक तथा परास्नातक की शिक्षा के लिए पाठ्यक्रम उपलब्ध कराया जाता है, तथा सभी विश्वविधालयों में पात्रता का अलग– अलग मापदंड होता है, उसी के आधार पर छात्रों का चयन किया जाता है |
<br>

<br>
<h1> Offered Distance Universities  </h1>

<!--Certicate Courses-->

<div id="accordion" class="myaccordion">
    
    <!--First Headming-->

    <div class="card">
  <div class="card-header" id="headingOne">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          Swami Vivekanand Subharti University
               
          <span class="fa-stack fa-lg">
                <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">


<!--Slider start-->
<p><img src="assets/education/brand/subharti.jpg" height="70" width="250"/> In exercise of the powers vested under section 7 (b) of the Act, Swami Vivekanand Subharti University has established the Directorate of Distance Education to help those who want to pursue higher studies, but are unable to do so through regular mode of education due to job constraints, family commitments or remoteness of location.
<br>
  The Distance Education Council of Government of India has accorded recognition to the University for offering programmes through distance education mode also vide letter no. DEC/Recog/2009/3174 dated 09.09.2009, on the recommendation of the Joint Committee of the University Grants Commission, All India Council for Technical Education and Distance Education Council. Accordingly, the University has started Distance Education programmes at undergraduate and post-graduate levels with effect from the academic year 2009-10 in various disciplines.
  <br>
  As per UGC letter no. F.9.37/2008(CPP-1) dated 06.02.2009, Swami Vivekanand Subharti University, being a State approved private University u/s 2(f) of UGC Act 1956, is competent to award degrees specified in section 22 of the UGC Act 1956. The programmes conducted by the University through distance education are identical in content to the courses offered through the normal mode. Consequently, as per Government of India Gazette Notification No. 44, F.No.18-15/93-TD.V/TS-IV dated 01.03.1995, the degrees awarded by the University through distance education stand automatically recognized for all purposes, including employment to posts and services under the Central Government.



<ul>
     <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.subhartidde.com/index.php" target="_blank"> https://www.subhartidde.com </a>

             

   
 </ul>








 </div>
 </div>
 </div>



<!--Diploma Courses-->


    
    <!--2nd Headming-->
 
    <div class="card">
  <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
Jaipur National University
          <span class="fa-stack fa-lg">
                <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>

    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">

<p><img src="assets/education/brand/jnu.jpg" height="70" width="200"/> The School of Distance Education and Learning (SODEL) of Jaipur National University was established in the year 2008-2009. In September, 2009 the University got approval from DEC and the Joint Committee of AICTE-UGC-DEC to start various programmes of studies at Under Graduate and Post Graduate levels. Easy access & quality assurance have been the motto of the School of Distance Education & Learning, and has all along maintained parity with education offered to regular students in colleges and universities. The students are provided quality study material in Self Learning format. In addition to this counselling is provided by qualified and experienced academic counsellors. One of the great advantages of Distance Learning is that it provides a viable and affordable alternative to conventional mode of education, especially for those, who for several socioeconomic, family and geographic constraints, could not get education despite their high motivation and genuine aptitude for studies, and also for those who do not have time for education through conventional mode because of their personal, professional and family obligations.
    <ul>
         <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "http://sodel.jnujaipur.ac.in/index.php/" target="_blank"> http://sodel.jnujaipur.ac.in</a>
    
            
    
       
     </ul>
    

</div>
</div>
</div>


    <!--3rd Headming-->
 
    <div class="card">
  <div class="card-header" id="headingThree">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
Graphic Era University
          <span class="fa-stack fa-lg">
                <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>

    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
      

    


        <p><img src="assets/education/brand/geu.jpg" height="70" width="200"/> With over 26 years of triumph and edification, Graphic Era University is the greatest advocate of being an integral fragment of a student’s universal growth and development. Our holistic and comprehensive approach towards the education of our students ensures a satisfying and lucrative career for one and all.
<br>
          We’re a family of the building blocks of a remarkable society. We’ve got a plethora of opportunities for each and every student. We, as educators, encourage all our students to find their niche and institute their place in this world.



            <ul>
                 <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://distance.geu.ac.in/" target="_blank"> https://distance.geu.ac.in/ </a>
            
                         
            
               
             </ul>
            





     </div>
    </div>
    </div>


<!--4th Heading-->
 
<div class="card">
    <div class="card-header" id="headingFour">
        <h2 class="mb-0">
          <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
   Suresh Gyan Vihar University
            <span class="fa-stack fa-lg">
                  <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
            </span>
          </button>
        </h2>
      </div>
  
      <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
        <div class="card-body">
        
  <p><img src="assets/education/brand/sgvu.png" height="70" width="200"/>  Fuel your career growth with a Self Paced and Live & Interactive Digital Learning distance program from SGVU. Research shows higher education is directly linked to career growth for working professional. However due to limitation of time & cost most professionals remain away from this opportunity. Suresh Gyan Vihar University's distance education programs - BBA,B.com & BA are designed for working professional which provides the students the convenience in learning without sacrificing career or time. These distance education programs are empowered by "Edusphere" to offer the students a true anytime, anywhere learning experience with Live & Interactive Digital Learning and robust Student Support. The course curriculum are designed to bridge the gap between academia and industry and thus leading a to a sure career growth.


    
   <ul>
         <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.sgvu.edu.in/" target="_blank"> https://www.sgvu.edu.in/ </a>
   </ul>
        </div>
        </div>
        </div>
    
<!--5th Heading-->
 
<div class="card">
    <div class="card-header" id="headingFive">
        <h2 class="mb-0">
          <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
Himalayan University
            <span class="fa-stack fa-lg">
                  <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
            </span>
          </button>
        </h2>
      </div>
  
      <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
        <div class="card-body">
        
  <p><img src="assets/education/brand/hu.png" height="70" width="100"/> To cater to the grave requirements of the working students and professionals, eminent Himalayan University of Itanagar (Arunachal Pradesh) also conducts opulent and prolific distance learning programs in arts & humanities and commerce disciplines at undergraduate and postgraduate levels. Since these affluent distance education courses are duly approved by the UGC-DEB, the degrees offered by these hold the same recognition and value as those offered by the regular campus-based courses at recognized Higher Education Institutes and universities, and hence, are equally acceptable to the government and private sectors.
<br>
    The study curricula and time schedules are to be selected by the pursuing learners as per the Choice Based Credit System (CBCS), and the learners have the liberty to proceed as per their respective pace and ease. MOOC coordinators and Training and Placement Cell will also be helping the candidates comprehensively.
   <ul>
         <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.himalayanuniversity.com/distance-education-admission/" target="_blank"> https://www.himalayanuniversity.com </a>
   </ul>
        </div>
        </div>
        </div>




