import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import{LayoutModule} from './layout/layout.module';
import{HeaderComponent} from './layout/header/header.component';
import{FooterComponent} from './layout/footer/footer.component';




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import { LayoutRoutingModule } from './layout/layout-routing.module';


import { RegistrationComponent } from './auth/registration/registration.component';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MyserviceService } from './Login Services';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule, MatOptionSelectionChange } from '@angular/material/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule, AlertConfig } from 'ngx-bootstrap/alert';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { EEducationModule } from './e-education/e-education.module';
import { EEducationRoutingModule } from './e-education/e-education-routing.module';






@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RegistrationComponent


   
      
   
  ,
    
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
 
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    LayoutModule,
    LayoutRoutingModule,
    
    ModalModule.forRoot(),
   
    EEducationModule,
    EEducationRoutingModule,
   
 
    
    ReactiveFormsModule,
    MatButtonModule,
    MatOptionModule,
    HttpClientModule,
    MatIconModule,
 
  
   
    AppRoutingModule

        
    
    
    
  ],
  providers: [MyserviceService ,[AlertConfig], {provide:LocationStrategy,useClass:HashLocationStrategy} ],

  
  bootstrap: [AppComponent]
})
export class AppModule { }
