<div class="container-fluid">

    <img src="assets/banners/missions.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Vision & Mission  </h1>
 <p>Our Mission
  Alex Institute is  a Society/NGO  Registered: Under the law of  Govt. Of India . its registered office is situated in Sunder Nagar-Mandi-H.P.
<br> <br>
Our Mission is to become the Best Institute in Himachal Pradesh.
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <h2> Our Vision  </h2>
        <ul class="inner-list" style="line-height:30px">
          <li>To provide modern and progressive educational facilities at a reasonable cost to the children.</li>
          <li>To nurture the student with rich capability of facing the challenge life efficiently and carve out their future.</li>
          <li>To provide all round best education to our students and to achieve the academic excellence along with personality development.</li>
          <li>To imbibe a sense of discipline, faith, hard work, leadership and consciousness towards their duties.</li>
        </ul>
    </div>

    <div class="col-sm-4">
  
            <h2> Our Aims  </h2>
            <ul class="inner-list" style="line-height:30px">
              <li>Focus on JOb Oriented Courses.</li>
              <!--<li>Personal coaching by renowned senior teachers (not by under-teacher)</li>-->
              <li>Individual attention to every student</li>
              <li>Multi media teaching</li>
              <li>Extensive notes and practice material</li>
              <li>To enrich the quality of life of the students through character building by creating and sustaining the urge to learning for life.</li>
              <li>To develop self-confidence to lead and motivate through practical training and exposure to Business and Industry.</li>
            </ul>
        </div>

      <div class="col-sm-4">
       
        <h2> Our Objective  </h2>
        <ul class="inner-list" style="line-height:30px">
          <li>To work for the welfare of the students.</li>
          <li>To impart quality education which enlightens the progress and prosperity of individual as well as nation.</li>
          <li>To inculcate the sense of leadership and management.</li>
          <li>To make students avail the opportunities to achieve academic excellence.</li>
          <li>To make lauching a 100% job Oriented and Self Employment Programs.</li>
        </ul>
      </div>
</div>