<div class="container-fluid">

    <img src="assets/banners/front office.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Reception Area   </h1>
   
  
  
   <div class="container">
    <div class="row">
      
      <div class="col-sm-3">
        <h2>Front Office </h2>
        <img class="img2" src="assets/banners/front office.jpg" style="width:100%;height:auto;"  /><br>
        
</div>
      <div class="col-sm-7">

   
        <p> 
            Every multi-departmental physical business needs to have a front office or reception to receive the visitors. Front Office Department is the face and as well as the voice of a business. Regardless of the star rating of the hotel or the hotel type, the hotel has a front office as its most visible department. For a business such as hospitality, the front office department comes with an aspect of elevating customer experience with the business.
        <br><br>

        Welcome to All in our Fron Desk for the counselling of students for any courses.
        </p>
         
         
       
    </div>



    <div class="col-sm-2 sidenav">
      <div class="sidebar">
        <a class="active" routerLink="/frontoffice" routerLinkActivate="Activate">Front Office </a>
        <a routerLink="/theoryroom" routerLinkActivate = "Activate"> Theory-Rooms</a>
                <a routerLink="/labs" routerLinkActivate = "Activate"> Labs</a>
       
              
        
        
        
        
        
        
        
      </div>
     
</div>


