<br /><br /><br />
<div class="footer">
  <footer>
    <div class="row">
      <div class="container"></div>
      <div class="col-md-4 col-sm-3 pad1">
        <h2>Our Locations</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3400.711342160393!2d76.88585251440307!3d31.53208715371991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390519a49344cdb9%3A0x715ad1062e8a67aa!2sARPAN%20FOUNDATION%20INSTITUTE%20OF%20TECHNOLOGY(%20Alex%20Institute)!5e0!3m2!1sen!2sin!4v1654059463594!5m2!1sen!2sin"
          width="350"
          height="250"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <!-- 
         <ul>
           <li><a href="#"><i class="fa fa-search" aria-hidden="true"></i> &nbsp; Home</a></li>
           <li><a routerLink="aboutus" routerLinkActivate = "Activate"><i class="fa fa-search" aria-hidden="true"></i> &nbsp; About Us</a></li>
           <li><a routerLink="gallery" routerLinkActivate = "Activate"><i class="fa fa-search" aria-hidden="true"></i> &nbsp; Gallery</a></li>
           <li><a routerLink="download" routerLinkActivate = "Activate"><i class="fa fa-search" aria-hidden="true"></i> &nbsp; Download</a></li>
           <li><a routerLink="terms" routerLinkActivate = "Activate"><i class="fa fa-search" aria-hidden="true"></i> &nbsp; Terms & Conditions</a></li>
           <li><a routerLink="contactus" routerLinkActivate = "Activate"><i class="fa fa-search" aria-hidden="true"></i> &nbsp; Contact Us</a></li>
          </ul> -->
      </div>
      <div class="col-md-5 col-sm-5 pad1">
        <h2>Contact Address Details:</h2>
        <address>
          <strong> ALEX INSTITUTE </strong><br />
          Above Punjab National Bank, <br />
          Teh. Sunder Nagar, Distt. Mandi, (H.P.) Pin: 175002<br />
          <i class="fa fa-search" aria-hidden="true"></i> &nbsp;+91
          94180-17280,70185-83091, <br />
          <i class="fa fa-search" aria-hidden="true"></i> &nbsp;
          <a href="mailto:ngpeducare@gmail.com">alexinstitute@gmail.com</a
          ><br />

          <i class="fa fa-search" aria-hidden="true"></i> &nbsp;
          <a href="https://alexinstitute.com" target="_blank">
            www.alexinstitute.com</a
          >
        </address>
      </div>
      <div class="col-md-3 col-sm-4 pad1">
        <h2>Follow Us On</h2>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/ngpeducare"
              target="_blank"
              title="Facebook"
              ><i class="fa fa-search" aria-hidden="true"></i> &nbsp;
              Facebook</a
            >
          </li>
        </ul>
        <br /><br /><br /><br />
        <p>
          Developed By :
          <a href="https://kewal.lokbiz.com" target="_blank"
            ><img
              src="assets/keyworldlogo.jpg"
              style="width: 170px; height: 90px"
              alt="Keyworld Softwares"
          /></a>
        </p>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 bottom py-2 bg-dark flex-shrink-0">
      &copy; <strong>Alex Institute</strong> 2022. All Rights Reserved.
    </div>
  </footer>
</div>
