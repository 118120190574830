<h1> Banking & Finance Courses </h1>
<b>Career In Banking & Finance : आधुनिक बैंकिंग प्रणाली का इतिहास भारत में दो सौ साल पुराना है इसकी शुरुआत 19 वी शतब्दी के आरंभ में ईस्ट इंडिया कंपनी ने ब्रिटिश शासन काल में की। भारत में सबसे पहले केंद्रीय बैंक के रूप में भारतीय रिजर्व बैंक सक्रिय हुई। वर्तमान समय में भारत में दी जाने वाली सेवाएं बैंक खाते, ऋण खाते, क्रेडिट और डेबिट कार्ड, लॉकर्स और धन हस्तंतरण आदि है जिन सेवाओं के माध्यम से आप आसानी से एक कोने से दुसरे कोने में पैसे भेज सकते है साथ ही साथ ऋण भी ले सकते है

भारत में बैंकिंग और फाइनेंस सेक्टर का तेजी से विस्तार हो रहा है। केवल शहरी क्षेत्रों में ही नहीं, बल्कि ग्रामीण क्षेत्रों में भी यह अब तेजी से पांव पसारने लगा है। एक अनुमान के मुताबिक, वर्ष 2025 तक भारत दुनिया का तीसरा सबसे बड़ा बैंकिंग हब हो सकता है। अगर आपका इंट्रेस्ट पैसों के मैनेजमेंट में है, तो सरकारी और निजी क्षेत्र के बैंकों में चमकदार करियर बना सकते हैं..


<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i>1. फाइनेंस मैनेजर<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i>2. फाइनेंशल प्लानर<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i>3. फाइनेंशल एनालिस्ट<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i>4. फाइनेंशल ऑडिटर<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i>5. इंवेस्टमेंट बैंकिंग एनालिस्ट<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i>6. एक्चुअरी<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i>7. अकाउंटेंट<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i>8. इंवेस्टर रिलेशन्स एसोसिएट<br>



<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->


<!--TABLE OF Courses START HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>
<h3 style="text-align:center">Certificate Courses</h3>
<div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Certificate in Tally ERP 9+</td>
							<td>3-Month</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Certificate in Marg ERP 9+</td>
							<td>3-Months</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
            <tr>
							<td>2.</td>
							<td>Certificate GST</td>
							<td>3-Months</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>         
            
        					</tbody>
				</table>
			</div>

<!--DIPLOMA Courses START HERE-->

      <h3 style="text-align:center"> Diploma Courses</h3>

      <div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Certified Business Accountant Executive</td>
							<td>1-Year</td>
							<td>12th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Financial Accounting</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
           




        					</tbody>
				</table>
			</div>


<!--DIPLOMA Courses END HERE-->


<!--Degree Courses START HERE--

<h3 style="text-align:center"> Degree Courses</h3>

<div class="table-responsive table-bordered  table-hover  ">
  <table class="table-striped">
    <tbody>
      <tr>
        <th>Sr. No.</th>
        <th>Course Name</th>
        <th>Duration</th>
        <th>Eligibility</th>
        <th>Education Mode </th>
        <th scope="col">Action</th>
      </tr>
      <tr>
        <td>1.</td>
        <td>BBA in Banking & Finance </td>
        <td>3-Year</td>
        <td>12th Pass</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>
      <tr>
        <td>2.</td>
        <td>MBA In Financial Management</td>
        <td>2-Year</td>
        <td>Any Graduate</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>
      <tr>
        <td>3.</td>
        <td>Bachelor of Commerce</td>
        <td>3-Year</td>
        <td>12th Pass</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>       
      
      <tr>
        <td>4.</td>
        <td>Master of Commerce</td>
        <td>2-Year</td>
        <td>B.com </td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>
      



  </tbody>
  </table>
</div>


<!--DEGREE Courses END HERE-->




