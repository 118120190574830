import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeSliderComponent } from './layout/home-slider/home-slider.component';






const routes: Routes = [
 
 
  { path: 'education',loadChildren: () => import(`./e-education/e-education.module`) .then(m =>m.EEducationModule) },
  { path: 'home',loadChildren: () => import(`./layout/layout.module`) .then(m =>m.LayoutModule) },


];


[
  //{path: '',  pathMatch: 'full', component: HomeComponent},
  //{path: 'home-slider', component: HomeSliderComponent},
 
  
  
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
