import { Component, OnInit } from '@angular/core';
import {CarouselConfig} from 'ngx-bootstrap/carousel'
import * as $ from 'jquery'
@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: false, showIndicators: true,
      pauseOnFocus:true,  slidesToShow: 1,
      slidesToScroll: 2,
      autoplay: true,
      autoplaySpeed: 2500,
      infinite: true,  } }
  ]
})
export class EducationComponent implements OnInit {

  itemsPerSlide = 4;
  singleSlideOffset = true;
  noWrap = false;
  showIndicators=false;
 
  slides = [
    {image: 'assets/1.jpeg'},
    {image: 'assets/2.jpeg'},
    {image: 'assets/3.jpeg'},
    {image: 'assets/4.jpeg'},
    {image: 'assets/1.jpeg'},
    
   
   
    
    
  ];
 
  onSlideRangeChange(indexes: number[]): void {
    $(document).ready(function(){
      $('.counter-value').each(function(){
          $(this).prop('Counter',0).animate({
              Counter: $(this).text()
          },{
              duration: 3500,
              easing: 'swing',
              step: function (now){
                  $(this).text(Math.ceil(now));
              }
          });
      });
    });
    
  }


  constructor() { }

  ngOnInit(): void {
  }

}
