<div class="container-fluid">

    <img src="assets/banners/Legal.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
 
   <h1> Accreditations   </h1>
   <div class ="container">
   <div class="row">
	  <div class="container">
	   <h2>Legal Recognition/Associations/Memberships/Colloborations</h2>
	  <hr/>
	  
	  </div>
	 </div>
  
   
   <div class="table-responsive table-bordered  ">
    <table class="table-striped table-hover">

     <thead>
       <tr>
         <th  class="text-center" scope="col">Sr.No.</th>
         <th  class="text-center" scope="col">Name of Documents</th>
         <th  class="text-center" scope="col">Download Link</th>
         
       </tr>

     </thead>
     <tbody>
       <tr *ngFor="let obj of dataArr">
         <td class="text-center">{{obj.id}}</td>
         <td class="text-center">{{obj.title}}</td>
         <td class="text-center"><a href="{{imageDirectoryPath}}/{{obj.path}}"> <button type="button" class="btn btn-dark"><i class="fa fa-download" aria-hidden="true" fa-4x></i>  Download </button> </a></td>
    
     
         
       </tr>
      
     </tbody>
   </table>
   </div>
  </div>