<div class="container-fluid">

    <img src="assets/banners/lab.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Institute-Labs  </h1>
  
   <div class="container">
    <div class="row">
      
      <div class="col-sm-3">
        <h2>Compuer Lab 1</h2>
        <img class="img2" src="assets/banners/computer.jpg" style="width:100%;height:auto;"  /><br>
        <h2>computer Lab 2</h2>
        <img class="img2" src="assets/banners/computer.jpg" style="width:100%;height:auto;"  />

</div>
      <div class="col-sm-7">

   
              
        <p> 
          Alex Institute has an excellent computer room where students work on the wonder-machines. Beginning from projects-making and extended to other areas, students are keen on getting knowledge and skill that will help them in their future. <br><br> There’s a high-tech computer lab with latest systems. <br><br>Facilitated with latest programs in graphics, animation and web-designing, this lab also provides the internet facility to the students.</p>

    </div>



    <div class="col-sm-2 sidenav">
      <div class="sidebar">
        <a class="active" routerLink="/frontoffice" routerLinkActivate="Activate">Reception Area </a>
        <a routerLink="/theoryroom" routerLinkActivate = "Activate"> Theory-Rooms</a>
                <a routerLink="/labs" routerLinkActivate = "Activate"> Labs</a>
       
              
        
        
        
        
        
        
        
      </div>
     
</div>
