<h1>Alex-Tuition For Students </h1>


 We provide a quality education with result oriented approach. In NGP e-Tuition. We emphasize on practical approach to bring out the best. We are fully equipped with technology to train our students to compete in ever changing competition world..We would ensure to provide proper guidance and a platform to our students according to all existing and future requirements by all organization..In the changing environment competition in the written examination is getting tough.

Now a day's most of the exams are being conducted online and students are bound to appear in these.  We at  NGP e-Tuition  are having one of the best online Tuition with videos & live  platforms in India. We use online Subjects tuition application not only to provide the real experience of actual exams but also to give our students the best possible analysis and assessment of their tests. <br><br> All the regular students of our NGP e-Tuition are provided their ID and password. Using these credential students not only take online tests, but also get a comprehensive analysis of his/her performances online by our launched app : NGP e-Tuition.


<h1> Choose Your Tuition Course</h1>
<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->
<table class="table table-hover  table-light">
  <thead>
    <tr>
      <th scope="col">Sr.No.</th>
      <th scope="col">Course Name</th>
      <th scope="col">Duration</th>
      <th scope="col">Eligibility</th>
      <th scope="col">Education Mode</th>
      <th scope="col">Total Fees</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Class 5th </td>
      <td>1-Year</td>
      <td>5th</td>
      <td>Online/offline</td>
      <td>1000</td>
      <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
      </td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Class-8th</td>
      <td>1-Year</td>
      <td>8th</td>
      <td>Online/offline</td>
      <td>1000</td>
      <td>
        <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
    </tr>
    
    <tr>
      <th scope="row">3</th>
      <td>Class-10th</td>
      <td>1-Year</td>
      <td>10th</td>
      <td>Online/offline</td>
      <td>1500</td>
    
      <td>
        <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
      </td>
  
    </tr>

    <tr>
      <th scope="row">4</th>
      <td>Class-11th Science</td>
      <td>1-Year</td>
      <td>11th</td>
      <td>Online/offline</td>
      <td>1500</td>
    
      <td>
        <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
      </td>
  
    </tr>
    <tr>
      <th scope="row">5</th>
      <td>Class 12th-Science</td>
      <td>1-Year</td>
      <td>12th</td>
      <td>Online/offline</td>
      <td>1500</td>
    
      <td>
        <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
      </td>
  
    </tr>
  </tbody>
</table>


