<h1> Coaching Courses </h1>
<b>Coaching Courses : The best coaching, the best chance of success. But, it totally dependent on candidate requirements, so make sure he/she choose the best IAS coaching institute based on the faculty experience, distance from your home, course fees, and your own strengths and your caliber on different subjects in English, Indian Polity Quantitative Aptitude, Decision Making, Logical Reasoning, History, Geography, Economy, Environment, Science and Current Affairs for IAS.</b>


<br>


<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->


<!--TABLE OF Courses START HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>
<h3 style="text-align:center">Coaching Courses</h3>
<div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>H.P.TET</td>
							<td>3-Month</td>
							<td>B.Ed Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>TGT</td>
							<td>3-Months</td>
							<td>B.Ed.</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
            <tr>
							<td>3.</td>
							<td>Drawing Teacher</td>
							<td>3-Months</td>
							<td>12th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>         
            
                        <tr>
							<td>4.</td>
							<td>Clerk</td>
							<td>3-Months</td>
							<td>12th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>         
            
                        <tr>
							<td>5.</td>
							<td>JOA</td>
							<td>3-Months</td>
							<td>12th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>     

        					</tbody>
				</table>
			</div>

<!--DIPLOMA Courses START HERE--

      <h3 style="text-align:center"> Diploma Courses</h3>

      <div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Certified Business Accountant Executive</td>
							<td>1-Year</td>
							<td>12th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Financial Accounting</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
           




        					</tbody>
				</table>
			</div>


<!--DIPLOMA Courses END HERE-->


<!--Degree Courses START HERE--

<h3 style="text-align:center"> Degree Courses</h3>

<div class="table-responsive table-bordered  table-hover  ">
  <table class="table-striped">
    <tbody>
      <tr>
        <th>Sr. No.</th>
        <th>Course Name</th>
        <th>Duration</th>
        <th>Eligibility</th>
        <th>Education Mode </th>
        <th scope="col">Action</th>
      </tr>
      <tr>
        <td>1.</td>
        <td>BBA in Banking & Finance </td>
        <td>3-Year</td>
        <td>12th Pass</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>
      <tr>
        <td>2.</td>
        <td>MBA In Financial Management</td>
        <td>2-Year</td>
        <td>Any Graduate</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>
      <tr>
        <td>3.</td>
        <td>Bachelor of Commerce</td>
        <td>3-Year</td>
        <td>12th Pass</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>       
      
      <tr>
        <td>4.</td>
        <td>Master of Commerce</td>
        <td>2-Year</td>
        <td>B.com </td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>
      



  </tbody>
  </table>
</div>


<!--DEGREE Courses END HERE-->




