

  <!--- main image slider -->

  <div class="container-fluid" id ="img-container">

    <app-home-slider></app-home-slider>
    
  </div>




  
  <!--- main body designs -->
<br>
<div class="container-fluid " id ="box-container">

  <div class="row div-pad">
    <div class="col-md-2 col-sm-2 pad">
        <div class="col-md-12 col-sm-12 icon2">
            <a routerLink="mission" routerLinkActivate="Active"><img src="assets/icons/125.png" alt="Mission" />
            <h5>Mission & Vision</h5></a>
        </div>
    </div>
    <div class="col-md-2 col-sm-2 pad">
        <div class="col-md-12 col-sm-12 icon2">
          <a routerLink="keyworldeducation" routerLinkActivate="Active"><img src="assets/icons/125.png" alt="Staff" />
            <h5>Our Courses</h5></a>
        </div>
    </div>
    <div class="col-md-2 col-sm-2 pad">
        <div class="col-md-12 col-sm-12 icon2">
            <a routerLink="fees" routerLinkActivate="Active"><img src="assets/icons/125.png" alt="Downloads" />
            <h5>Fees Structures</h5></a>
        </div>
    </div>
    <div class="col-md-2 col-sm-2 pad">
        <div class="col-md-12 col-sm-12 icon2">
          <a routerLink="legal" routerLinkActivate="Active"><img src="assets/icons/125.png" alt="Library" />
            <h5>Our Acceridiations</h5></a>
        </div>
    </div>
    <div class="col-md-2 col-sm-2 pad">
        <div class="col-md-12 col-sm-12 icon2">
          <a routerLink="toppers" routerLinkActivate="Active"><img src="assets/icons/125.png" alt="Prospectus" />
            <h5>Our Achievements</h5></a>
        </div>
    </div>
    <div class="col-md-2 col-sm-2 pad ">
        <div class="col-md-12 col-sm-12 icon2">
          <a routerLink="contactus" routerLinkActivate="Active"><img src="assets/icons/125.png" alt="Audited Reports" />
            <h5>Send Message</h5></a>
        </div>
    </div>
</div>

</div>
<br>
<!--- main welcome designs -->


  <section id="call-to-action-2">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-6">
          <h2>Welcome to All in Alex Institute  </h2> <br>
          <p>Welcome to our website.
As we all know that in today’s world computer education is necessary for every individual. Education is not complete without the knowledge of computer. <br><br> Education enables a person to realize his duties & responsibilities towards the society and his country. Economic growth can be achieved only through better human resources. In fact, “Human Resources” is the most significant strength; an institution can ever develop to provide a competitive edge to the society in the Global perspective. <br> <br>Arpan Foundation believes that human capitals is the key for national growth and choose education as means to excel in creating skills & knowledge by improving the quality of human resource. We aims to become a centre for excellence and dedicates itself to meet the vision of India by transforming students into highly talented individual with commitment, integrity and patriotism. I, Whole heartedly with best of luck, to all those who seek admission, we welcome you to our door to success.
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="img-box1"><img src ="assets/icons/125.png"> </div> 
          <h1> Alex Institute</h1>
          <a routerLink="president" routerLinkActivate="Active" class="btn btn-success">Read More</a>
          
         
        </div>
      </div>
    </div>
  </section>





  <div class ="container-fluid">
<h2>Our Courses </h2>
<!--slider boxes starts-->
<br>


<div class="slider-box"><p  class = "details"> Computer Courses </p>
  <div class="img-box"><a routerLink ="/computer-courses"routerLinkActivate = "Activate"> 
  <img src ="assets/icons/13.png"></a>
  </div>
 
  <div class="cart">
 <a routerLink ="/computer-courses" routerLinkActivate = "Activate"> View More  </a>
  </div>
  </div>


  <div class="slider-box"><p  class = "details"> Accounts & Finance Courses </p>
      <div class="img-box"><a routerLink ="/finance-courses"routerLinkActivate = "Activate"> 
      <img src ="assets/icons/10.png"></a>
      </div>
     
      <div class="cart">
     <a routerLink ="/contactus" routerLinkActivate = "Activate"> View More  </a>
      </div>
      </div> 


      <div class="slider-box"><p  class = "details"> Regular University Courses </p>
          <div class="img-box"><a routerLink ="/regular-university"routerLinkActivate = "Activate"> 
          <img src ="assets/icons/6.png"></a>
          </div>
         
          <div class="cart">
         <a routerLink ="/regular-university" routerLinkActivate = "Activate"> View More  </a>
          </div>
          </div>
          
          <div class="slider-box"><p  class = "details"> Distance University Courses </p>
              <div class="img-box"><a routerLink ="/distance-university"routerLinkActivate = "Activate"> 
              <img src ="assets/icons/4.png"></a>
              </div>
             
              <div class="cart">
             <a routerLink ="/distance-university" routerLinkActivate = "Activate"> View More  </a>
              </div>
              </div> 
              
              <div class="slider-box"><p  class = "details"> Coaching Courses </p>
                  <div class="img-box"><a routerLink ="/coaching-courses"routerLinkActivate = "Activate"> 
                  <img src ="assets/icons/25.png"></a>
                  </div>
                 
                  <div class="cart">
                 <a routerLink ="/hotel-courses" routerLinkActivate = "Activate"> View More  </a>
                  </div>
                  </div>
              
                  <div class="slider-box"><p  class = "details"> I.T. Courses </p>
                      <div class="img-box"><a routerLink ="/IT-courses"routerLinkActivate = "Activate"> 
                      <img src ="assets/icons/8.png"></a>
                      </div>
                     
                      <div class="cart">
                     <a routerLink ="/IT-courses" routerLinkActivate = "Activate"> View More  </a>
                      </div>
                      </div>
   
                      <div class="slider-box"><p  class = "details"> Teacher Training Courses </p>
                        <div class="img-box"><a routerLink ="/teacher-courses"routerLinkActivate = "Activate"> 
                        <img src ="assets/icons/27.png"></a>
                        </div>
                       
                        <div class="cart">
                       <a routerLink ="/teacher-courses" routerLinkActivate = "Activate"> View More  </a>
                        </div>
                        </div>
                               
<!--
                          <div class="slider-box"><p  class = "details"> Beauty & Wellness Courses </p>
                              <div class="img-box"><a routerLink ="/beauty-courses"routerLinkActivate = "Activate"> 
                              <img src ="assets/icons/14.png"></a>
                              </div>
                             
                              <div class="cart">
                             <a routerLink ="/beauty-courses" routerLinkActivate = "Activate"> View More  </a>
                              </div>
                              </div>
                              
                              <div class="slider-box"><p  class = "details"> Fire & Safety Courses </p>
                                  <div class="img-box"><a routerLink ="/safety-courses"routerLinkActivate = "Activate"> 
                                  <img src ="assets/icons/17.png"></a>
                                  </div>
                                 
                                  <div class="cart">
                                 <a routerLink ="/safety-courses" routerLinkActivate = "Activate"> View More  </a>
                                  </div>
                                  </div>
                                  

                                  <div class="slider-box"><p  class = "details"> Teacher Training Courses </p>
                                      <div class="img-box"><a routerLink ="/teacher-courses"routerLinkActivate = "Activate"> 
                                      <img src ="assets/icons/18.png"></a>
                                      </div>
                                     
                                      <div class="cart">
                                     <a routerLink ="/teacher-courses" routerLinkActivate = "Activate"> View More  </a>
                                      </div>
                                      </div>
                                      

                                      <div class="slider-box"><p  class = "details"> Retail Sales Courses </p>
                                          <div class="img-box"><a routerLink ="/retail-courses"routerLinkActivate = "Activate"> 
                                          <img src ="assets/icons/20.png"></a>
                                          </div>
                                         
                                          <div class="cart">
                                         <a routerLink ="/retail-courses" routerLinkActivate = "Activate"> View More  </a>
                                          </div>
                                          </div>
                                          
                                          <div class="slider-box"><p  class = "details"> Technical Trade Courses </p>
                                              <div class="img-box"><a routerLink ="/engineering-courses"routerLinkActivate = "Activate"> 
                                              <img src ="assets/icons/21.png"></a>
                                              </div>
                                             
                                              <div class="cart">
                                             <a routerLink ="/engineering-courses" routerLinkActivate = "Activate"> View More  </a>
                                              </div>
                                              </div>
                                              

                                              <div class="slider-box"><p  class = "details"> Fashion Designing Courses </p>
                                                  <div class="img-box"><a routerLink ="/fashion-courses"routerLinkActivate = "Activate"> 
                                                  <img src ="assets/icons/16.png"></a>
                                                  </div>
                                                 
                                                  <div class="cart">
                                                 <a routerLink ="/fashion-courses" routerLinkActivate = "Activate"> View More  </a>
                                                  </div>
                                                  </div>
                                                 
                                                  <div class="slider-box"><p  class = "details"> Paramedical Courses </p>
                                                      <div class="img-box"><a routerLink ="/paramedical-courses"routerLinkActivate = "Activate"> 
                                                      <img src ="assets/icons/19.png"></a>
                                                      </div>
                                                     
                                                      <div class="cart">
                                                     <a routerLink ="/paramedical-courses" routerLinkActivate = "Activate"> View More  </a>
                                                      </div>
                                                      </div>
                                                      

                                                      <div class="slider-box"><p  class = "details"> Pharamacy Courses </p>
                                                          <div class="img-box"><a routerLink ="/ayurveda-courses"routerLinkActivate = "Activate"> 
                                                          <img src ="assets/icons/22.png"></a>
                                                          </div>
                                                         
                                                          <div class="cart">
                                                         <a routerLink ="/ayurveda-courses" routerLinkActivate = "Activate"> View More  </a>
                                                          </div>
                                                          </div>
                                                          
                                                   <div class="slider-box"><p  class = "details"> Nursing Courses </p>
                                                              <div class="img-box"><a routerLink ="/nursing-courses"routerLinkActivate = "Activate"> 
                                                              <img src ="assets/icons/24.png"></a>
                                                              </div>
                                                             
                                                              <div class="cart">
                                                             <a routerLink ="/nursing-courses" routerLinkActivate = "Activate"> View More  </a>
                                                              </div>
                                                              </div>
                                                              

                                                             <div class="slider-box"><p  class = "details"> Law Courses </p>
                                                                  <div class="img-box"><a routerLink ="/law-courses"routerLinkActivate = "Activate"> 
                                                                  <img src ="assets/icons/23.png"></a>
                                                                  </div>
                                                                 
                                                                  <div class="cart">
                                                                 <a routerLink ="/law-courses" routerLinkActivate = "Activate"> View More  </a>
                                                                  </div>
                                                                  </div>
                                                                -->  

<div class="slider-box"><p  class = "details"> Tuitions  </p>
  <div class="img-box"><a routerLink ="/e-tuition"routerLinkActivate = "Activate"> 
  <img src ="assets/icons/3.png"></a>
  </div>
 
  <div class="cart">
 <a routerLink ="/contactus" routerLinkActivate = "Activate"> View More  </a>
  </div>
  </div> 


  <div class="slider-box"><p  class = "details"> Open Schooling Admission </p>
      <div class="img-box"><a routerLink ="/open-school"routerLinkActivate = "Activate"> 
      <img src ="assets/icons/2.png"></a>
      </div>
     
      <div class="cart">
     <a routerLink ="/contactus" routerLinkActivate = "Activate"> View More  </a>
      </div>
      </div> 

      <div class="slider-box"><p  class = "details"> Others All Courses </p>
          <div class="img-box"><a routerLink ="/e-tuition"routerLinkActivate = "Activate"> 
          <img src ="assets/icons/7.png"></a>
          </div>
         
          <div class="cart">
         <a routerLink ="/contactus" routerLinkActivate = "Activate"> View More  </a>
          
          </div> 
</div>

</div>
<br>




   <!---Notice Board or News etc starts -->  
   
    <div class="container-fluid text-center">    
      <div class="row content">

        <div class="col-sm-3 sidenav">
          <h2>Important Links </h2>
          <ul>
            <li><a href="https://hpbose.org/" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp;       HP Board of School Education </a></li>
            <li><a href="https://hpbose.org/HPSOS/Index.aspx" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp;  HPSOS </a></li>
            <li><a href="https://hpbose.org/HPSOS/Index.aspx" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp;  HPSOS Result </a></li>
            <li><a href="http://cbse.nic.in/ecbse/index.html" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp; e-CBSE</a></li>
            <li><a href="http://www.iitmandi.ac.in/" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp; IIT Mandi</a></li>
                     
            <li><a href="http://mhrd.gov.in/" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp; MHRD-Govt. of India</a></li>
            <li><a href="https://himachal.nic.in/en-IN/" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp; Govt. of Himachal Pradesh</a></li>
            <li><a href="http://mygov.in/" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp; Mygov.in</a></li>
            <li><a href="https://www.nios.ac.in/" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp; National Institute of Open Schooling</a></li>
            
            <li><a href="https://www.nielit.gov.in/" target="_blank"><i class="fa fa-search" aria-hidden="true"></i>  &nbsp;&nbsp; NIELIT</a></li>
          </ul>
        </div>

        <div class="col-sm-6 text-left"> 
    
         <!-- slider box-->


      <div class="container">
          <!-- slider -->
          <h2 style="background:rgb(43, 42, 42);color:greenyellow; margin:10px;">
            <marquee> Admission Open!! Hurry Up!! Enroll Yourself or Call us!!</marquee>
        </h2>
    
          <div class="container-fluid" id ="wrapper">
           
                 
                <carousel >


                      <slide>
                        <img src="assets/1.jpeg" alt="first slide" style="height: 500px; width:100%;">
                        <div class="carousel-caption d-lg-block ">
                          
                        </div>
                      </slide>
                    
                      <slide>
                        <img src="assets/2.jpeg" alt="first slide" style="height: 500px; width:100%;">
                        <div class="carousel-caption d-lg-block ">
                          
                        </div>
                      </slide>
                    
                      <slide>
                        <img src="assets/3.jpeg" alt="first slide" style="height: 500px; width:100%;">
                        <div class="carousel-caption d-lg-block ">
                          
                        </div>
                      </slide>

                      <slide>
                        <img src="assets/4.jpeg" alt="first slide" style="height: 500px; width:100%;">
                        <div class="carousel-caption d-lg-block ">
                          
                        </div>
                      </slide>


                   

                    </carousel>
              </div>
              
              </div>
          
           
          <!-- end slider -->
        


        </div>


        <div class="col-sm-3 sidenav">
          <h2>News & Events</h2>
          <h3>News and Events</h3>
      <marquee scrollamount="3" behavior="scroll" direction="up" onMouseOver="stop()" onMouseOut="start()" style="padding:5px; height:200px">
        <p>Welcome to All in Alex Institute -News & Events Info kindly click on bellow button:-<br/><small>Dated : 16-05-2022</small></p>
        <p>Alex Institute launched its new website.<br/><small>Dated : 06-06-2022</small></p>
       <a routerLink="news" routerLinkActivate="Active" class="btn btn-primary"> View More</a>
       <hr style="color:#808080"/>
      </marquee>
          </div>

</div>

    <!--- number end slider -->  
 
    <!-- brand partner marquee -->


  <!-- brand events photos --



 
    
         

<div cloass ="container">

  <section class="teams" id="teams">
    

      <h2> Our Branch Head </h2>
  <carousel [showIndicators]="showIndicators" [itemsPerSlide]="itemsPerSlide"
            [singleSlideOffset]="singleSlideOffset"
            [noWrap]="noWrap"
    
         
      
            [startFromIndex]="1"
        
            (slideRangeChange)="onSlideRangeChange($event)">
          <slide *ngFor="let slide of slides; let index=index" class="mx-auto">
   <div class="card"> <div class="box"> <img [src]="slide.image" style="display: block; width: 100%;" >
  
                </div>
            </div>
      <div class="carousel-caption"></div>
        
    
    </slide>
    
  </carousel>

   </section>
  

 
   
   <h2> Our Brand Partner </h2>

   <div class="container"> 
   
   <marquee behavior="scroll" scrollamount="3" direction="left" onmouseover="stop()"
        onmouseout="start()" style="padding: 5px;">
      
          
        <img src="assets/keyworldlogo.jpg" width="200" height="100" alt="KeyWorld" style="padding:5px"/>
        <img src="assets/brand/1.png" width="150" height="100" alt="NGP Educare" style="padding:5px"/>
        <img src="assets/brand/2.png" width="200" height="100" alt="KeyWorld" style="padding:5px"/>
        
        
        <img src="assets/brand/4.jpg" width="200" height="100" alt="KeyWorld" style="padding:5px"/>
        <img src="assets/brand/5.jpg" width="150" height="100" alt="NGP Educare" style="padding:5px"/>
        <img src="assets/brand/7.jpg" width="200" height="100" alt="KeyWorld" style="padding:5px"/>
        
        
        
        <img src="assets/brand/9.png" width="150" height="100" alt="NGP Educare" style="padding:5px"/>
        <img src="assets/brand/10.png" width="200" height="100" alt="KeyWorld" style="padding:5px"/>
        <img src="assets/brand/11.jpg" width="150" height="100" alt="NGP Educare" style="padding:5px"/>
        <img src="assets/brand/RAMADA.png" width="150" height="100" alt="NGP Educare" style="padding:5px"/>
      
      
   
          
        
   
         </marquee>
   
   </div>
   

   


  
  -->