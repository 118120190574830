import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { EEducationRoutingModule } from './e-education-routing.module';
import { EducationComponent } from './education/education.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { EduSliderComponent } from './edu-slider/edu-slider.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionComponent } from './accordion/accordion.component';
import { ETuitionComponent } from './e-tuition/e-tuition.component';
import { KeyEduComponent } from './key-edu/key-edu.component';
import { OpenSchoolComponent } from './open-school/open-school.component';
import { SkillBasedComponent } from './skill-based/skill-based.component';
import { FinanceCoursesComponent } from './finance-courses/finance-courses.component';
import { HotelCoursesComponent } from './hotel-courses/hotel-courses.component';
import { ITCoursesComponent } from './it-courses/it-courses.component';
import { BeautyCoursesComponent } from './beauty-courses/beauty-courses.component';
import { SafetyCoursesComponent } from './safety-courses/safety-courses.component';
import { TeacherCoursesComponent } from './teacher-courses/teacher-courses.component';
import { RetialCoursesComponent } from './retial-courses/retial-courses.component';
import { FashionCoursesComponent } from './fashion-courses/fashion-courses.component';
import { EngineeringCoursesComponent } from './engineering-courses/engineering-courses.component';
import { AgricultureCoursesComponent } from './agriculture-courses/agriculture-courses.component';
import { ArtsCoursesComponent } from './arts-courses/arts-courses.component';
import { MediaCoursesComponent } from './media-courses/media-courses.component';
import { ParamedicalCoursesComponent } from './paramedical-courses/paramedical-courses.component';
import { AyurvedaCoursesComponent } from './ayurveda-courses/ayurveda-courses.component';
import { NursingCoursesComponent } from './nursing-courses/nursing-courses.component';
import { LawCoursesComponent } from './law-courses/law-courses.component';
import { TuitionDetailsComponent } from './tuition-details/tuition-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { FormsModule} from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';
import { RegularUniversityComponent } from './regular-university/regular-university.component';
import { DistanceUniversityComponent } from './distance-university/distance-university.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CoachingCoursesComponent } from './coaching-courses/coaching-courses.component';
import { NielitCoursesComponent } from './nielit-courses/nielit-courses.component';


@NgModule({
  declarations: [EducationComponent, EduSliderComponent, AccordionComponent, ETuitionComponent, KeyEduComponent, OpenSchoolComponent, SkillBasedComponent, FinanceCoursesComponent, HotelCoursesComponent, ITCoursesComponent, BeautyCoursesComponent, SafetyCoursesComponent, TeacherCoursesComponent, RetialCoursesComponent, FashionCoursesComponent, EngineeringCoursesComponent, AgricultureCoursesComponent, ArtsCoursesComponent, MediaCoursesComponent, ParamedicalCoursesComponent, AyurvedaCoursesComponent, NursingCoursesComponent, LawCoursesComponent, TuitionDetailsComponent, RegularUniversityComponent, DistanceUniversityComponent, CoachingCoursesComponent, NielitCoursesComponent],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
  
    BrowserAnimationsModule,
    EEducationRoutingModule
  ]
})
export class EEducationModule { }
