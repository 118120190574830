<div class ="container-fluid"></div>
 <section class="banner">
  <div class="banner-box">
    <div class="intro-text">
      
         
  <carousel >

  <slide>
    <img src="assets/01.jpeg" alt="first slide" style="display: block; width:100%">
    <div class="carousel-caption d-lg-block ">
      
    </div>
  </slide>

<slide>
    <img src="assets/02.jpg" alt="section slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>

  <slide>
    <img src="assets/03.jpeg" alt="third slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>

<slide>
    <img src="assets/04.jpeg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>
  <slide>
    <img src="assets/05.jpeg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>
  
  <slide>
    <img src="assets/03.jpeg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>

  <slide>
    <img src="assets/04.jpeg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>


  <slide>
    <img src="assets/05.jpeg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>

  <slide>
    <img src="assets/05.jpeg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>

  



</carousel>
        </div>
    </div>

