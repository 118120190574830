<div class="container-fluid">

    <img src="assets/banners/fees.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>

  
   <h1> Fees-Structures  </h1>

   
  <!-- Page Content -->
	<div class="row div-pad">
		<div class="container" id ="table-container">

      <h2 style="text-align:center">"Our Fees Structure For Regular Courses" </h2>

      <div class="table-responsive table-bordered table-hover ">
        <table class="table-striped">
  
       <thead>
         <tr >
           <!--<th class="text-center" scope="col">Sr.No.</th>-->
           <th class="text-center" scope="col">Course Name </th>
           <th class="text-center" scope="col"> Admission Fees </th>
           <th class="text-center" scope="col"> Monthly Fees </th>
           <th class="text-center" scope="col"> Registeration Fees </th>
           <th class="text-center" scope="col"> Others Fees </th>


         </tr>
       </thead>
       <tbody>
  
         <tr *ngFor="let obj of dataArr">
           <!--<td class="text-center">{{obj.id}}</td>-->
           <td class="text-center">{{obj.class}}</td>
           <td class="text-center">{{obj.admission_fees}} </td>
           <td class="text-center">{{obj.monthly_fees}} </td>
           <td class="text-center">{{obj.registeration_fees}} </td>
           <td class="text-center">{{obj.others_fees}} </td>

           

       
           
         </tr>
        
       </tbody>
     </table>
     </div>
     

            <br/>


         
		</div>
	
</div>