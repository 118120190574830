
<!--- Educslider image slider -->

<div class="container-fluid">

 <app-edu-slider> </app-edu-slider>
  
</div>






<!-- smartpage page designs -->

<div class="wrapper">
  
 <article class="main">
  <h2>All Courses Details </h2>
<div>

  
                  
<router-outlet></router-outlet>



</div>




 </article>



 
<!-- The sidebar of education page -->

<div class="sidebar">
 <a class="active" a routerLink="keyworldeducation" routerLinkActivate="active">KeyWorld Education</a>
 <a routerLink="/computer-courses" routerLinkActivate="active">Computer Courses </a>
 <a routerLink="/finance-courses" routerLinkActivate="active"> Banking & Finance Coureses </a>
 <a routerLink="/coaching-courses" routerLinkActivate="active"> Coaching Courses </a>
 <a routerLink="/teacher-courses" routerLinkActivate="active"> Teacher Training Coureses </a>
 <a routerLink="/IT-courses" routerLinkActivate="active"> I.T. Coureses </a>
 <a routerLink="/regular-university" routerLinkActivate="active"> Regular university </a>
 <a routerLink="/distance-university" routerLinkActivate="active"> Distance university </a>
 <a routerLink="/teacher-courses" routerLinkActivate="active"> Teacher Training Courses</a>
 <a routerLink="/e-tuition" routerLinkActivate="active"> Alex-Tuitions </a>
 <a routerLink="/open-school" routerLinkActivate="active"> Open School  </a>



 <!--
 <a routerLink="/skill-based" routerLinkActivate="active"> Skill Based Coureses </a>
 
  <a routerLink="/finance-courses" routerLinkActivate="active"> Banking & Finance Coureses </a>
  <a routerLink="/hotel-courses" routerLinkActivate="active"> Hotel & Tourism Courses </a>
 
 
  <a routerLink="/beauty-courses" routerLinkActivate="active">Beauty & Wellness Courses  </a>
  <a routerLink="/safety-courses" routerLinkActivate="active"> Fire & Safety Coureses </a>

  <a routerLink="/retail-courses" routerLinkActivate="active"> Retail Sales Courses </a>
  <a routerLink="fashion-courses" routerLinkActivate="active"> Fashion Designing Courses </a>
  <a routerLink="/engineering-courses" routerLinkActivate="active"> Engineering  Courses </a>
  <a routerLink="/arts-courses" routerLinkActivate="active"> Arts & Science Courses </a>
  <a routerLink="/media-courses" routerLinkActivate="active"> Journalism & Media Courses </a>
  <a routerLink="/agriculture-courses" routerLinkActivate="active">Agriculture Coureses </a>
  <a routerLink="/paramedical-courses" routerLinkActivate="active"> Paramedical Coureses </a>
  <a routerLink="/ayurveda-courses" routerLinkActivate="active"> Ayurveda & Pharamacy Coureses </a>
  <a routerLink="/nursing-courses" routerLinkActivate="active"> Nursing Coureses </a>
  <a routerLink="law-courses" routerLinkActivate="active"> Law Coureses </a>
 
 -->
 

  
  
</div>
</div>

  
<!-- Call To Action --
<section class="cta">
  <div class="cta-inner overlay section" style="background-image:url('assets/banners/admission.jpg')" data-stellar-background-ratio="0.5">
    <div class="container">
      <div class="row">					
        <div class="col-lg-8 col-md-8 col-12">
          <h1>What we Offers?</h1>
          <div class="text-content">
          
            
                      
              <ul>
               <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i> &nbsp;Online Books</li>
        
    <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Online Test Results</li>
    <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Interactive Contents</li>
    <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Online Student Profile</li>
    <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i> &nbsp;Online Training Videos</li>
    <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;100% supports assurance.</li>
    <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Mock Tests and Questions</li>
    <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i> &nbsp;Online Student Login/Panel</li>
    
   
       <li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Govt. recognized Certificate.</li>
<li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;100% Job Placement Assistance</li>
<li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Learn 24/7 – Anywhere Anytime</li>
<li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Work Integrated Training Programs</li>
<li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Both Mode (Offline & online Programs)</li>
<li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Freedom to choose any Course or Competitive Test</li>
<li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp;Industrial Training with Scholarships in some selected Programs</li>
<li><i class="fa fa-check-square fa-lg" aria-hidden="true"></i>  &nbsp; Free KeyWorld Jobs Portal for Finding Local to International Jobs for our Students. </li>
      
     
              </ul>
            <!-- CTA Button -->
           
            <!--/ End CTA Button --
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <!-- Cta Image --
          <div class="cta-image">
            <img src="assets/banners/girl.png" alt="#">
          </div>
          <!--/ End Cta Image --
        </div>
      </div>
    </div>
  </div>
</section>
<!--/ End Call To Action -->




  <!-- numbers counters starts --
  <h2> Our Happy Students </h2>
  <div class="wrapper" id ="numbers">
    <div class="row">
     
        <div class="col-md-3 col-sm-6">
       
            <div class="counter">
                <div class="counter-icon">
                    <i class="fa fa-globe"></i>
                </div>
                <span class="counter-value">500</span>
                <h3>+Students</h3>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="counter purple">
                <div class="counter-icon">
                    <i class="fa fa-rocket"></i>
                </div>
                <span class="counter-value">70</span>
                <h3>+Courses</h3>
            </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="counter">
              <div class="counter-icon">
                  <i class="fa fa-globe"></i>
              </div>
              <span class="counter-value">50</span>
              <h3>+Govt.Selection</h3>
          </div>
      </div>
      <div class="col-md-3 col-sm-6">
          <div class="counter purple">
              <div class="counter-icon">
                  <i class="fa fa-rocket"></i>
              </div>
              <span class="counter-value">100</span>
              <h3>%Job Assistance</h3>
          </div>
      </div>
    </div>
</div>

-->
<br>
<div cloass ="container">

  <section class="teams" id="teams">
    

      <h2> Trending Ads </h2>
      <br>
  <carousel [showIndicators]="showIndicators" [itemsPerSlide]="itemsPerSlide"
            [singleSlideOffset]="singleSlideOffset"
            [noWrap]="noWrap"
    
         
      
            [startFromIndex]="1"
        
            (slideRangeChange)="onSlideRangeChange($event)">
          <slide *ngFor="let slide of slides; let index=index" class="mx-auto">
   <div class="card"> <div class="box"> <img [src]="slide.image" style="display: block; width: 100%;" >
  
                </div>
            </div>
      <div class="carousel-caption"></div>
        
    
    </slide>
    
  </carousel>

   </section>
  

 


 <!-- ======= Appointment/Enquiry Section ======= --
 <section id="appointment" class="appointment section-bg">
  <div class="container">

    <div class="section-title">
      <h2>Send Enquiry</h2>
     
    </div>

    <form action="forms/appointment.php" method="post" role="form" class="php-email-form">
      <div class="row">
        <div class="col-md-4 form-group">
          <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
          <div class="validate"></div>
        </div>
        <div class="col-md-4 form-group mt-3 mt-md-0">
          <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email">
          <div class="validate"></div>
        </div>
        <div class="col-md-4 form-group mt-3 mt-md-0">
          <input type="tel" class="form-control" name="phone" id="phone" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
          <div class="validate"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 form-group mt-3">
          <input type="tel" class="form-control" name="phone" id="phone" placeholder="Your WhatsApp No." data-rule="minlen:4" data-msg="Please enter at least 4 chars">
          <div class="validate"></div>
        </div>
        <div class="col-md-4 form-group mt-3">
          <select name="department" id="department" class="form-select">
            <option value="">Select Department</option>
            <option value="Department 1">NGP e-Tuition</option>
            <option value="Department 2">KeyWorld Online Courses</option>
            <option value="Department 3">KeyWorld Open Schooling</option>
            <option value="Department 3">KeyWorld Universities</option>  
             <option value="Department 3">KeyWorld Vocational Courses</option>  
             <option value="Department 3">KeyWorld Skill Based Courses</option>
          </select>
          <div class="validate"></div>
        </div>
        <div class="col-md-4 form-group mt-3">
          <select name="doctor" id="doctor" class="form-select">
            <option value="">Select Courses</option>
            <option value="Doctor 1">Any Course</option>
           
          </select>
          <div class="validate"></div>
        </div>
      </div>

      <div class="form-group mt-3">
        <textarea class="form-control" name="message" rows="5" placeholder="Message (Optional)"></textarea>
        <div class="validate"></div>
      </div>
      
      <div class="text-center"> <button type="button" class="btn btn-primary">Send Enquiry</button> </div>
    </form>

  </div>
</section><!-- End Appointment Section --
              
      <br>    
            
          
         
          
                       
           
          
        
      

 <h1> Advertisement </h1>

 


 <div class="wrapper" id ="container3"> 

										
<carousel [showIndicators]="showIndicator" [itemsPerSlide]="itemsPerSlide"
          [singleSlideOffset]="singleSlideOffset"
          [noWrap]="noWrap"
    
          [startFromIndex]="4"
      
          (slideRangeChange)="onSlideRangeChange($event)">
 <slide *ngFor="let slide of slides; let index=index" class="mx-auto">
    	<div class="img-box" id ="advt"> <img [src]="slide.image" alt="image slide" style="display: block; width: 100%;"class="img-responsive center-block">
    <div class="carousel-caption"></div>
      
    </div>
  </slide>
</carousel>
 

</div>

<!-- brand partner marquee --

<h1> Brand Partner </h1>

 <div class="container"> 

<marquee behavior="scroll" scrollamount="3" direction="left" onmouseover="stop()"
			onmouseout="start()" style="padding: 5px;">
		
				
			<img src="assets/education/brand/1.jpg" width="150" height="100" alt="BIG GROUP OF EDUCATION" style="padding:5px"/>
      <img src="assets/education/brand/2.jpg" width="150" height="100" alt="BIG GROUP OF EDUCATION" style="padding:5px"/>
      <img src="assets/education/brand/3.jpg" width="150" height="100" alt="BIG GROUP OF EDUCATION" style="padding:5px"/>
      <img src="assets/education/brand/4.png" width="150" height="100" alt="BIG GROUP OF EDUCATION" style="padding:5px"/>
		
		
		

				
			

			 </marquee>

 </div>
-->