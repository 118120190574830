import { Component, OnInit } from '@angular/core';
import { MyserviceService } from 'src/app/Login Services';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  dataArr:any;
  data: any;
  // For localhost legal download files path:
  //imageDirectoryPath:any ='http://127.0.0.1:8080/public/schooladmin/storage/app';

   // For localhost download files path for live server
   imageDirectoryPath:any ='https://alexinstitute.com/alexadmin/storage/app';

  constructor(
      private myservice:MyserviceService  ) { }

  ngOnInit() {

    this.getDownloadData();
    this.DownloadFile();
  }
  getDownloadData()
  {
    this.myservice.getDownload(this.data).subscribe(res=>{
      this.dataArr=res;
    })
  }
    DownloadFile(): void
    {
      this.myservice.DownloadFile(this.data).subscribe(data=>{
        this.data =data;
        this.dataArr=this.DownloadFile;
      })
     
      
    }
  }



