
<div class="container-fluid">

  <img src="assets/banners/contact.jpg" alt="first slide" style="display: block; width: 100%;">
  

</div>

<br><br>

 <body>
   
   <div class="container">
<br>

    <h4> Contact Us</h4>
     <div class="content">
       <div class="left-side">
         <div class="address details">
          <i class="fa fa-map-marker" aria-hidden="true"></i>
           <div class="topic">Address :</div>
           <div class="text-one"><b> Alex Institute Above PNB ,Tehsil Sunder Nagar,
            Distt. Mandi, (H.P.)
PIN: 175002. </b> </div>
        
         </div>
         <div class="phone details">
          <i class="fa fa-phone" aria-hidden="true"></i>
           <div class="topic">Contact Us:</div>
           <div class="text-one">  Mob : 94180-17280, </div> 
           <div class="text-one">  Mob  : 70185-83091 , </div> 
       
         <div class="email details">
          <i class="fa fa-envelope-o" aria-hidden="true"></i>
           <div class="topic">Email:</div>
           <div class="text-one">alexinstitue@gmail.com</div>
          
         </div>
       </div>
</div>
       <div class="right-side">

        <div class="topic-text"><u> Send Us a Message </u></div>
        
        <div class="formcontainer">
          <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
            <mat-form-field appearance= "fill" style="width:100%;">
            
            <mat-label> <b> Mob No :</b> </mat-label> 
            <input type="text"  formControlName ="mobile_no" matInput placeholder=" Enter Your Mob No :">
                      
            </mat-form-field>
                     
            </div>  
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        <br><br>
            <div style="text-align: center; color :rgb(29, 134, 15)">                
              
             <alert type="success">
              <strong>{{successMessage}} </strong>
              </alert> 
             </div>
         


        </form>
        </div>
        </div>
   </div>

</div> </div> 
 

</body>


















            

		
<!-- Modal -->
 <!--- main image slider -->






 

	






 
<!----
  </body>
    <div class="col-md-9 ">
     <h2>Our Location</h2>
		<div class="mapouter"><div class="gmap_canvas"><iframe width="500" height="550" id="gmap_canvas" src="https://maps.google.com/maps?q=ngpesmartlife.com&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div><style>.mapouter{position:relative;text-align:left;height:600px;width:600px;}.gmap_canvas {overflow:hidden;background:none!important;height:600px;width:600px;}</style></div>
 </div>
         <div class="page-desc">
            <div class="row justify-content-left">
              <div class="col-md-4">
                <h2>Contact Us</h2>
                <form>
                  <div class="form-group">
                    <input type="text" name="name" class="form-control" placeholder="Name">
                  </div>
                  <div class="form-group">
                    <input type="text" name="email" class="form-control" placeholder="E-Mail">
                  </div>
                  <div class="form-group">
                    <input type="text" name="phone" class="form-control" placeholder="Phone">
                  </div>
                  <div class="form-group">
                    <textarea name="message" rows="5" class="form-control" placeholder="Message"></textarea>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-success">Send</button>
                  </div>
                </form>
         
         </div>



		
		<div class="col-md-2 col-sm-2 pad">
			<div class="table-responsive">
				<table style="border-collapse: separate; border-spacing: 0px; width: 100%;">
					<tr>
						 <h2>Corporate Office</h2>
					</tr>
					<tr>
						<td style="font-size: 12px" align="left"><b>Near CANARA Bank, Near New Bus-stand, Tehsil Sunder Nagar,
            	Distt. Mandi, (H.P.)
PIN: 175002. </b>
							
							<span style="font-weight: bold">Mobile No. : </span>+91-83509-80311 ,+91-80911-80311.
							
							<span style="font-weight: bold">E-mail : </span><a style="color: #e5720e" href="http://ngpesmartlife@gmail.com/">ngpesmartlife@gmail.com</a>
							<br />
							<br />
							<span style="font-weight: bold">Website : </span><a style="color: #e5720e" target="_blank" href="http://www.ngpesmartlife.com/">www.ngpesmartlife.com</a>
							<br />
							<br />
						</td>
					</tr>
					</table>
</div>
		</div>
    <div class="col-md-5 col-sm-5 pad">
     <h2>Our Location</h2>
		<div class="mapouter"><div class="gmap_canvas"><iframe width="500" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=ngpesmartlife.com&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div><style>.mapouter{position:relative;text-align:right;height:500px;width:600px;}.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}</style></div>
 </div>
        </div>
     </div>  
    -->