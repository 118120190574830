<div class="container-fluid">

    <img src="assets/banners/chairman.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Chairman-cum-MD-Message    </h1>
   <br> <br> <br>
   <div class="container">
   
  

    <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <img class="img2" src="assets/banners/chairman.jpg" style="width:100%;height:auto;" alt="Anuradha Jain" />
            <h2> Mr. Rakesh  </h2>
        </div>
          <div class="col-sm-8">
            <p> The Indian youth are facing serious problems of unemployment and underemployment. This is due to the phenomenal rise in population and lack of proper infrastructural facilities to provide good quality education which will earn them a good living. Although Government is taking massive efforts to improve the scenario, still majority of the population is not able to get basic infrastructural facilities for their career development. Keeping this in the mind, (Kewal Institute of Technical & Vocational Industrial Training) has designed courses carefully taking into account the actual need of the students and the applicability of the subject knowledge in the jobs they are going to undertake in the immediate future.
              <br><br>
              Vocational Education Programmes has the ability to create corporate ready workforce. Also, it offers end-to-end skill building programmes focusing on improving the employability quotient of aspirants. Skilled manpower is usually considered a sine qua non of industrialization. Skilled workers are not born; in fact every worker has the potential of becoming a skilled worker and this potential can be realized by providing adequate opportunities for Vocational Education and Training.
              <br><br>
             
            </p>
            <h2> CMD: Alex Institute  </h2>
          </div>
</div>

<br> <br> <br>
  
  
  
  